import teacherAvatar from '@public/imgs/default-teacher.png';
import dayjs from 'dayjs';
import { TFunction } from 'next-i18next';
import { TIME_FORMAT_WITHOUT_SEC } from '@/utils/constants';
import { ClassTypeEnum } from './interface';
import { toDay } from '@/utils/utils';

export const getLessonTeacherAvatar = (wkHeadImageUrl: string, contentUrl: string) => {
  return wkHeadImageUrl || contentUrl || teacherAvatar.src;
};

export const getFormatStartTime = (t: TFunction, startDateTime: string, endDateTime?: string, locale?: string) => {
  // const date = dayjs(startDateTime).format(DATE_FORMAT_WITH_POINT);
  // const week = t(WEEKS_FORMAT[dayjs(endDateTime).day()]);
  const startTime = dayjs(startDateTime).format(TIME_FORMAT_WITHOUT_SEC);
  const endTime = dayjs(endDateTime).format(TIME_FORMAT_WITHOUT_SEC);
  return endDateTime
    ? `${toDay(endDateTime, locale, 'YYYY-M-D (dddd)')} ${startTime}-${endTime}`
    : `${toDay(startDateTime, locale, 'YYYY-M-D (dddd)')} ${startTime}`;
};

export const formatDuration = (time: number) => {
  const hour = Math.floor(time / 3600);
  const minute = Math.floor((time / 60) % 60);
  const second = Math.floor(time % 60);

  if (hour >= 1) {
    return '01:00:00';
  }
  if (hour < 1 && minute >= 1) {
    return `${minute < 10 ? `0${minute}` : minute}:${second < 10 ? `0${second}` : second}`;
  }
  return `00:${second < 10 ? `0${second}` : second}`;
};

const ClassType = {
  [ClassTypeEnum.PRIVATE]: '私教课',
  [ClassTypeEnum.GROUP]: '班课',
};

export const getClassTypeName = (value: keyof typeof ClassTypeEnum) => {
  return ClassType[value];
};
// 自主选课来源标识
export const SELF_FROM_HOME = 'webhomepage';
export const SELF_FROM_MY_COURSE = 'webmycourse';
export const SELF_FROM_PAY = 'pay';
